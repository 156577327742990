section.block-anchor-menu ul.anchor-menu {
    margin-bottom: 0;
}
section.block-anchor-menu>.content:first-of-type {
    padding-bottom: 2rem;
}
section.block-anchor-menu .content:not(:first-of-type) {
    padding-top: 0;
}

section.block-anchor-menu .content {
    /*padding: var(--block-space-vertical-small) var(--block-space-horizontal);*/
    /*padding-left: 2rem;*/
    /*padding-right: 2rem;*/
    margin: 0 auto;
    max-width: var(--width-text);
    width: 100%;
}

section.block-anchor-menu .tableContentTitle{
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 2rem;
    line-height: 1.2;
}

section.block-anchor-menu .contentTitle {
    /*font-size: var(--font-size);*/
    border-bottom: 4px solid var(--color-yellow);
    padding-bottom: 4px;
    width: fit-content;
    margin-bottom: var(--default-spacing);
    display: block;
}

/* Donate block specific adjustments in anchor */
section.block-anchor-menu .block-donate .contentTitle {
    margin-bottom: 0;
}

/*section.block-anchor-menu .contentTitle.centered {*/
/*    text-align: left;*/
/*    margin: 0 auto 2rem;*/
/*    display: block;*/
/*}*/
.block-anchor-menu {
    margin: var(--block-space-vertical-small);

}
ul.anchor-menu {
    background-color: var(--color-grey-light);
    padding: var(--block-space-vertical-small);
    max-width: var(--width-text);
    /*margin: var(--block-space-vertical-small);*/
}
ul.anchor-menu h3 {
    margin-bottom: 10px;
}

ul.anchor-menu a {
    text-decoration: none;
}

ul.anchor-menu a li {
    width: 90%;
    overflow: hidden;
    /*text-overflow: ellipsis;*/
    /*height: 20px;*/
    /*white-space: nowrap;*/
}

ul.anchor-menu a:hover {
    text-decoration: none;
    color: var(--color-yellow);
}

ul.anchor-menu a:not(:last-child) li {
    margin-bottom: 0.5rem;
}

ul.anchor-menu a:last-child li {
    margin-bottom: 0;
}

ul.anchor-menu li.anchor-list-point {
    font-weight: bold;
    list-style-type: none;
}

.d-none {
    display: none !important;
}

/* add top Scroll Padding to not Overlap in Text */
html {
    scroll-padding-top: var(--nav-height);
}

section.block-anchor-menu {
    margin: 0;
}

section.block-anchor-menu .anchor-title-container {
    margin-bottom: 1rem;
}
section.block-anchor-menu .contentTitle.centered {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2rem;
    display: block;
}

@media (max-width: 480px)  {
    /*section.block-anchor-menu .content {*/
    /*    padding-left: var(--block-space-horizontal);*/
    /*    padding-right: var(--block-space-horizontal);*/
    /*}*/
}

@media (max-width: 680px) {
    section.block-anchor-menu .block-infopoint {
        text-align: left;
    }
}

@media (min-width: 480px) {
    section.block-anchor-menu {
        margin: 0;
    }
}

@media (min-width: 640px) {
    section.block-anchor-menu {
        /*margin: var(--block-space-vertical-small);*/
        margin: 0;
    }
}
